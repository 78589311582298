/* eslint-disable */
import assign from 'core-js-pure/features/object/assign';
import 'core-js/features/array/for-each'
import 'core-js/features/array/find'
import 'core-js/features/array/includes'
import 'core-js/features/string/includes'

if (NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach
}

Object.assign = assign

